import './TeamS2.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Counter from '../../../components/Counter/Counter';
import Heading from '../../../components/Heading/Heading';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import Text from '../../../components/Text/Text';

const TeamS2 = ({ headingRT = {}, tableRows = [] }) => {
  if (!headingRT.text && !tableRows.length) {
    return null;
  }

  return (
    <Section className="team-s2">
      <Row mdJustify="center">
        <Col md={6}>
          <div className="team-s2__wrapper">
            <ScrollTrigger>
              {headingRT.text && (
                <Heading>
                  <RichText render={headingRT.raw} />
                </Heading>
              )}
            </ScrollTrigger>
          </div>
        </Col>
      </Row>
      <Row mdJustify="center">
        <Col xx={9} xxOffset={3} md={6} mdOffset="off">
          <div className="team-s2__wrapper">
            <ScrollTrigger>
              {tableRows.length > 0 && (
                <Table>
                  {tableRows.map((row) => (
                    <TableRow key={row.text.text + row.value}>
                      <Counter attrs={{ start: row.value }} value={row.value} type={row.value_type} />
                      <Text>
                        <RichText render={row.text.raw} />
                      </Text>
                    </TableRow>
                  ))}
                </Table>
              )}
            </ScrollTrigger>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

export default TeamS2;
