import './TeamMemberBox.scss';

import cx from 'classnames';
import { RichText } from 'prismic-reactjs';
import React, { useState } from 'react';

import minus from '../../images/minus.svg';
import plus from '../../images/plus.svg';
import { usePageIsMobile } from '../../utils/context';
import Image from '../Image/Image';
import Text from '../Text/Text';

const TeamMemberBox = ({ bioRT = {}, isBig, nameRT = {}, profileIMG = {}, roleRT = {} }) => {
  const [isBioVisible, setIsBioVisible] = useState(false);
  const isMobile = usePageIsMobile();

  const rootCx = cx('team-member-box', {
    'team-member-box--big': isBig,
    'team-member-box--bio-visible': isMobile && isBioVisible,
  });

  const handleButtonClick = () => {
    setIsBioVisible(!isBioVisible);
  };

  return (
    <div className={rootCx}>
      <div className="team-member-box__image-wrapper">{profileIMG.fluid && <Image {...profileIMG} />}</div>
      <div className="team-member-box__personals">
        <div className="team-member-box__name">
          {nameRT.text && (
            <Text>
              <RichText render={nameRT.raw} />
            </Text>
          )}
          {roleRT.text && (
            <Text>
              <RichText render={roleRT.raw} />
            </Text>
          )}
        </div>
        {bioRT.text && (
          <button className="team-member-box__button" onClick={handleButtonClick} type="button">
            {isBioVisible ? (
              <React.Fragment>
                Close
                <img src={minus} alt="" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                View Bio
                <img src={plus} alt="" />
              </React.Fragment>
            )}
          </button>
        )}
        <div className="team-member-box__bio">
          <Text>
            <RichText render={bioRT.raw} />
          </Text>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberBox;
