import './TeamS1.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Image from '../../../components/Image/Image';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import Text from '../../../components/Text/Text';

const TeamS1 = ({ animationTrigger, descRT = {}, headIMG = {}, headingRT = {} }) => {
  if (!headingRT.text && !headIMG.fluid) {
    return null;
  }

  return (
    <Section className="team-s1">
      <Row>
        {/* left */}
        <Col xx={12} md={3} mdOffset={1} mdOrder="first">
          <ScrollTrigger desktopSet={{ to: { delay: 0.4, scrollTrigger: { trigger: animationTrigger } } }}>
            {headingRT.text && (
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            )}
          </ScrollTrigger>
        </Col>
        {/* right */}
        <Col xx={12} xxOrder="first" md={6} mdOffset={1}>
          <ScrollTrigger
            allSet={{ to: { scrollTrigger: { trigger: animationTrigger } } }}
            desktopSet={{ to: { delay: 0.8 } }}
            mobileSet={{ to: { delay: 0.4 } }}
          >
            {headIMG.fluid && <Image {...headIMG} />}
          </ScrollTrigger>
        </Col>
        {/* bottom */}
        <Col xx={12} md={6} mdOffset={5}>
          <ScrollTrigger>
            {descRT.text && (
              <Text>
                <RichText render={descRT.raw} />
              </Text>
            )}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default TeamS1;
