import './TeamS3.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import TeamMemberBox from '../../../components/TeamMemberBox/TeamMemberBox';

const TeamS3 = ({ headingRT = {}, members = [] }) => {
  if (!headingRT.text && !members.length) {
    return null;
  }

  return (
    <Section className="team-s3">
      <Row>
        <Col md={10} mdOffset={1}>
          {headingRT.text && (
            <ScrollTrigger>
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            </ScrollTrigger>
          )}
          {members.length > 0 && (
            <div className="team-s3__members">
              {members.map(({ member_desc = {}, member_image = {}, member_name = {}, member_role = {} }, idx) => (
                <ScrollTrigger
                  key={member_name.text}
                  desktopSet={{ to: { delay: idx === 0 ? 0 : ((idx - 1) % 3) * 0.5 } }}
                >
                  <TeamMemberBox
                    key={member_name.text}
                    bioRT={member_desc}
                    isBig={idx === 0}
                    nameRT={member_name}
                    profileIMG={member_image}
                    roleRT={member_role}
                  />
                </ScrollTrigger>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Section>
  );
};

export default TeamS3;
