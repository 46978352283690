import './TeamHero.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Heading from '../../../components/Heading/Heading';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const TeamHero = ({ openingRT = {} }, ref) => {
  if (!openingRT.text) {
    return null;
  }

  return (
    <Section className="team-hero" isWane ref={ref}>
      <ScrollTrigger>
        <Heading>
          <RichText render={openingRT.raw} />
        </Heading>
      </ScrollTrigger>
    </Section>
  );
};

export default React.forwardRef(TeamHero);
