import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import React, { useRef } from 'react';

import TeamHero from './TeamHero/TeamHero';
import TeamS1 from './TeamS1/TeamS1';
import TeamS2 from './TeamS2/TeamS2';
import TeamS3 from './TeamS3/TeamS3';

const OurTeamPage = ({
  data: {
    page: {
      data: {
        hero_opening_text,
        section_1_desc,
        section_1_image,
        section_1_title,
        section_2_title,
        section_2_table_rows,
        section_3_members,
        section_3_title,
      } = {},
    } = {},
  } = {},
}) => {
  const heroRef = useRef(null);

  return (
    <React.Fragment>
      {/* Hero */}
      <TeamHero openingRT={hero_opening_text} ref={heroRef} />

      {/* Section 1 */}
      <TeamS1
        animationTrigger={heroRef.current}
        descRT={section_1_desc}
        headIMG={section_1_image}
        headingRT={section_1_title}
      />

      {/* Section 2 */}
      <TeamS2 headingRT={section_2_title} tableRows={section_2_table_rows} />

      {/* Section 3 */}
      <TeamS3 headingRT={section_3_title} members={section_3_members} />
    </React.Fragment>
  );
};

export default withPreview(OurTeamPage);

export const query = graphql`
  query($lang: String) {
    page: prismicOurTeamPage(lang: { eq: $lang }) {
      data {
        hero_opening_text {
          raw
          text
        }

        section_1_desc {
          raw
          text
        }
        section_1_image {
          alt
          fluid(maxWidth: 640) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        section_1_title {
          raw
          text
        }

        section_2_table_rows {
          text {
            raw
            text
          }
          value
          value_type
        }
        section_2_title {
          raw
          text
        }

        section_3_members {
          member_desc {
            raw
            text
          }
          member_image {
            alt
            fluid(maxWidth: 520) {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
          member_name {
            raw
            text
          }
          member_role {
            raw
            text
          }
        }
        section_3_title {
          raw
          text
        }
      }
    }
  }
`;
